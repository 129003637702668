/* global __DEV__ */

const config = {

  config: {
    development: process.env.NODE_ENV === 'development',
  },
  api: {

    release: {
      cms: 'https://cmsservice.teraportfoy.com/api/',
    },

    development: {
      cms: 'https://cmsservice.teraportfoy.com/api/',
    },
    cms: (url) => (config.config.development ? config.api.development.cms : config.api.release.cms),

  },

};

export default config;
