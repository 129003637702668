import { axiosCMSService } from './axios.config';

const endpoints = {
  create: 'news/create',
  update: 'news/update',
  approveupdate: 'news/approveupdate',
  delete: 'news/delete',
  alllist: 'news/alllist',
  getbyid: 'news/getbyid',
  getapprovelist: 'news/getapprovelist',
  getbycategoryid: 'news/getbycategory',
  getbypageid: 'news/getbypageid',
  updatestatus: 'news/updatestatus',
  updateapprove: 'news/updateapprove',
  linkedfile: 'news/linkedfile',
  unlinkedfile: 'news/unlinkedfile',
};

const NewsService = {

  async Create(data) {
    return axiosCMSService.post(endpoints.create, data);
  },
  async Update(data) {
    return axiosCMSService.post(endpoints.update, data);
  },
  async UpdateStatus(data) {
    return axiosCMSService.post(endpoints.updatestatus, data);
  },
  async Delete(data) {
    return axiosCMSService.delete(`${endpoints.delete}?id=${data}`, data);
  },
  async AllList(filterApproved = false) {
    return axiosCMSService.get(`${endpoints.alllist}?filterApproved=${filterApproved}`);
  },
  async GetById(data) {
    return axiosCMSService.post(endpoints.getbyid, data);
  },
  async GetByCategoryId(data) {
    return axiosCMSService.post(endpoints.getbycategoryid, data);
  },
  async GetByPageId(data) {
    return axiosCMSService.get(endpoints.getbypageid, data);
  },
  async GetApproveList(data) {
    return axiosCMSService.get(endpoints.getapprovelist, data);
  },
  async ApproveUpdate(status, data) {
    return axiosCMSService.post(`${endpoints.updateapprove}?status=${status}`, data);
  },
  async LinkedFile(data) {
    return axiosCMSService.post(endpoints.linkedfile, data);
  },
  async UnLinkedFile(data) {
    return axiosCMSService.post(endpoints.unlinkedfile, data);
  },

};

export default NewsService;
