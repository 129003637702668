import { SearchOutlined } from '@mui/icons-material';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, InputAdornment, List, ListItemButton, ListItemIcon, ListItemText, TextField } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { NotifyContext } from '../../../../contexts/NotifyContext';
import PageService from '../../../../services/pageService';
import SectionService from '../../../../services/sectionService';

function LinkedDialog({ open, setOpen, onChange }) {
  const [sectionList, setSectionList] = useState();
  const [filter, setFilter] = useState();
  const { Notify } = useContext(NotifyContext);
  const [checked, setChecked] = useState([]);

  const getSections = async () => {
    const result = await SectionService.AllList();
    setSectionList(result?.data);
  };
  // eslint-disable-next-line no-shadow
  const linkSection = async ({ checked, pageId }) => {
    const result = await PageService.LinkSection({ sectionId: checked, pageId });
    getSections();
    if (!result?.error) {
      setChecked([]);
      setOpen();
      onChange();
      Notify(result, 'Bağlantı başarılı', 'success');
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    getSections();

    return (
      () => setChecked([])
    );
  }, []);
  return (
    <Dialog PaperProps={{ sx: { borderRadius: '10px', height: '80vh' } }} fullWidth open={!!open} onClose={() => setOpen(!open)}>

      <DialogTitle color="warning.light" id="scroll-dialog-title">Bölüm Bağla</DialogTitle>
      <TextField
        value={filter}
        onChange={(x) => setFilter(x.currentTarget.value)}
        sx={{ mx: 4 }}
        label="Ara"
        variant="standard"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlined />
            </InputAdornment>
          ),
        }}
      />
      <DialogContent>
        <List>
          {sectionList?.filter((x) => !open?.pageSections?.some((y) => y.id === x.id)).filter((x) => (filter ? x.name.toUpperCase().includes(filter?.toUpperCase()) : true))?.map((x, index) => (
            <>
              <ListItemButton onClick={handleToggle(x.id)} key={x.id}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(x.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText
                  primary={x.name}
                />
              </ListItemButton>
              <Divider />
            </>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => linkSection({ checked, pageId: open.pageId })}>Ekle</Button>
        <Button color="error" onClick={() => setOpen()}>İptal</Button>

      </DialogActions>
    </Dialog>
  );
}

export default LinkedDialog;
