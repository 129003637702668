import styled from '@emotion/styled';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import { Box, Collapse, Typography, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSpring, animated } from '@react-spring/web';

const useStyles = makeStyles(() => ({
  root: {
    '& .labelInfo': {
      visibility: 'hidden',
    },
    '&:hover .labelInfo': {
      visibility: 'visible',
    },
  },
}));

function TransitionComponent(props) {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });
  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

function CustomTreeItem(props) {
  const classes = useStyles();
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    ...other
  } = props;

  return (
    <TreeItem
      TransitionComponent={TransitionComponent}
      label={(
        <Box className={classes.root} sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
            {labelText}
          </Typography>
          <Box className="labelInfo" variant="caption" color="inherit">
            {labelInfo}
          </Box>
        </Box>
      )}
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      {...other}
    />
  );
}

const StyledTreeItem = styled(CustomTreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

export default StyledTreeItem;
