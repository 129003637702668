const { createContext, useState, useMemo } = require('react');

const ButtonLoaderContext = createContext();

function ButtonLoaderProvider({ children }) {
  const [loading, setLoading] = useState();

  const contextValue = useMemo(() => ({
    loading, setLoading,
  }), [loading]);

  return (
    <ButtonLoaderContext.Provider value={contextValue}>
      {children}
    </ButtonLoaderContext.Provider>

  );
}

export { ButtonLoaderContext, ButtonLoaderProvider };
