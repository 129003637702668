import { Autocomplete, Box, CardMedia, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useState } from 'react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '../../components/Button';
import { NotifyContext } from '../../contexts/NotifyContext';
import { DataContext } from '../../contexts/DataContext';
import NewsService from '../../services/newsService';
import PageService from '../../services/pageService';
import CategoryService from '../../services/categoryService';
import FileService from '../../services/fileService';

function SelectComponent({ inputLabel, label, value, onChange, children, disabled }) {
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{inputLabel}</InputLabel>
      <Select
        defaultValue=""
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label={label}
        onChange={onChange}
        disabled={disabled}
      >
        {children}
      </Select>
    </FormControl>
  );
}
const renderOption = (props, option) => (
  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
    <CardMedia
      component="img"
      height="50"
      image={option.path}
      style={{ width: '50px' }}
    />
    {option.name}
    .
    {option.extension}
  </Box>
);
function NewsEditor({ data, tabName, pageid, onChange }) {
  const [body, setBody] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [categoryId, setCategoryId] = React.useState(data?.categoryId);
  const [subject, setSubject] = React.useState(data?.subject);
  const [pageList, setPageList] = React.useState();
  const [pageId, setPageId] = React.useState(pageid || '');
  const { setNewsList } = React.useContext(DataContext);
  const [categoryList, setCategoryList] = React.useState();
  const { Notify } = React.useContext(NotifyContext);
  const editorRef = React.useRef(0);
  const [fileList, setFileList] = useState([]);
  const [addItemFile, setAddItemFile] = React.useState([]);

  const getFiles = async () => {
    await FileService.AllList().then((response) => {
      setFileList(response?.data);
    });
  };
  const handleChangeCategory = async (event) => {
    setCategoryId(event.target.value);
  };
  const textFieldOnChange = (event) => {
    setSubject(event.target.value);
  };

  const AllNews = async () => {
    const result = await NewsService.AllList();
    setNewsList(result?.data);
  };
  const getCategory = async () => {
    const result = await CategoryService.Alllist();
    return result;
  };
  const Delete = async (id) => {
    const result = await NewsService.Delete(data.id).then((response) => {
      if (!response?.error) { onChange(); getFiles(); }
    });
  };

  useEffect(() => {
    getCategory().then((x) => setCategoryList(x.data));
    getFiles();
  }, []);

  useEffect(() => {
    if (tabName !== 0) {
      const GetById = async () => {
        const result = await NewsService.GetById({ id: data?.id, filterApproved: false });
        if (result?.data) {
          setBody(result?.data.body);
        }
      };
      GetById();
    } else {
      const getPage = async () => {
        const result = await PageService.Alllist();
        if (pageid) {
          setPageList(result?.data.filter((f) => f.id === pageid));
        } else {
          setPageList(result?.data);
        }
      };
      getPage();
    }
  }, [data]);

  const AddPdf = (pdf) => {
    const tempBody = editorRef.current.getContent() || '';
    const y = tempBody.concat(`<p><iframe src="${pdf}" width="100%" height="100%"></iframe></p>`);
    editorRef.current.setContent(y);
  };
  const AddImg = (img) => {
    const tempBody = editorRef.current.getContent() || '';
    const y = tempBody.concat(`<p><img src="${img}" width="100%" height="100%"></img></p>`);
    editorRef.current.setContent(y);
  };

  const createNews = async () => {
    if (pageId === undefined || categoryId === undefined || subject === undefined) {
      Notify('Zorunlu alanları doldurunuz!', 'error');
    } else {
      setLoading(true);
      const result = await NewsService.Create({
        categoryId,
        pageId,
        body: editorRef.current.getContent(),
        subject,
      });
      AllNews();
      onChange();
      setLoading(false);
    }
  };

  const handleChangePage = async (event) => {
    setPageId(event.target.value);
  };
  const Update = async () => {
    if (subject === '') {
      Notify('Zorunlu alanları doldurunuz!', 'error');
    } else {
      await NewsService.Update({
        id: data?.id,
        categoryId,
        subject,
        body: editorRef.current.getContent(),
        status: data?.status,
      });
    }
  };
  const UploadFile = async (uploadFile, isSetBody) => {
    if (uploadFile) {
      const file = new FormData();
      file.append('Files', uploadFile);
      file.append('NewsId', data.id);
      await FileService.Upload(file).then((res) => {
        if (!res?.error) {
          getFiles();
          NewsService.GetById({ id: data.id }).then((response) => {
            onChange({ data: response?.data, type: 'editNews' });
          });
        }
      });
    }
  };

  const UnLinkedFile = async (newsId, fileId) => {
    await NewsService.UnLinkedFile({ newsId, fileId }).then((res) => {
      if (!res?.error) {
        NewsService.GetById({ id: data?.id, filterApproved: false }).then((response) => {
          onChange({ data: response?.data, type: 'editNews' });
        });
      }
    });
  };
  const LinkedFile = async (newsId, fileId) => {
    await NewsService.LinkedFile({ newsId, fileId }).then((res) => {
      if (!res?.error) {
        NewsService.GetById({ id: data?.id, filterApproved: false }).then((response) => {
          onChange({ data: response?.data, type: 'editNews' });
        });
      }
    });
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', columnGap: 5 }}>
        <SelectComponent disabled={tabName === 2} inputLabel="Kategori" value={categoryId || ''} label="Category" onChange={handleChangeCategory}>
          {categoryList?.map(({ name, id }, index) => (
            <MenuItem key={index} value={id}>{name}</MenuItem>
          ))}
        </SelectComponent>
        <TextField fullWidth id="outlined-basic" disabled={tabName === 2} label="Konu" defaultValue={data?.subject} variant="outlined" onChange={textFieldOnChange} />
      </Box>
      {tabName === 0 && (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', columnGap: 20, mt: 3 }}>
        <SelectComponent inputLabel="Sayfa" value={pageId || ''} label="Page" onChange={handleChangePage}>
          {pageList?.map((item) => (
            <MenuItem key={item?.id} value={item?.id}>{item?.subMenuItemName === null ? `${item?.menuItemName}` : `${item?.subMenuItemName}`}</MenuItem>
          ))}
        </SelectComponent>
      </Box>
      )}
      {tabName !== 2 && (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', columnGap: 5, mt: 3 }}>
        <TextField
          disabled={tabName === 0}
          fullWidth
          id="outlined-basic"
          variant="outlined"
          type="file"
          draggable
          onChange={(e) => (UploadFile(e.target.files[0]))}
        />
        <Autocomplete
          options={fileList}
          disabled={tabName === 0}
          fullWidth
          getOptionLabel={(option) => (option ? `${option.name}.${option.extension}` : '')}
          renderOption={(props, option) => renderOption(props, option)}
          onChange={(event, value) => {
            if (value) {
              LinkedFile(data.id, value.id);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Sunucudan Dosya Seç"
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
      </Box>
      )}
      <Typography
        color="primary"
        variant="body1"
        sx={{ mt: 3 }}
      >
        Sectiona ait pdf ve fotoğraflar:
      </Typography>
      <Typography
        color="orange"
        variant="body2"
      >
        Pdf ve fotoğrafların üzerine tıklayarak editöre ekleyebilirsiniz. Zip dosyalarına tıklayarak indirebilirsiniz.
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {data?.files?.length ?
          data?.files?.map((item) => (
            <Box key={item.id}>
              <IconButton>
                {(() => {
                  if (item.extension === 'pdf') {
                    return (
                      <Box sx={{ flexDirection: 'column' }}>
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={() => {
                            AddPdf(item.path);
                          }}
                          aria-label="close"
                          sx={{}}
                        >
                          <PictureAsPdfIcon sx={{ fontSize: 40 }} />
                        </IconButton>
                        <Typography align="center">
                          {`${item.name.slice(0, 5)}....${item.extension}`}
                        </Typography>
                      </Box>
                    );
                  }
                  if (item.extension === 'zip') {
                    return (
                      <Box sx={{ flexDirection: 'column' }}>
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={() => window.open(`${item.path}`, '_blank')}
                          aria-label="close"
                        >
                          <FolderZipIcon color="primary" sx={{ fontSize: 40 }} />
                        </IconButton>
                        <Typography align="center">
                          {`${item.name.slice(0, 5)}....${item.extension}`}
                        </Typography>
                      </Box>
                    );
                  }

                  return (
                    <Box sx={{ flexDirection: 'column' }}>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => {
                          AddImg(item.path);
                        }}
                        aria-label="close"
                        sx={{}}
                      >
                        <img src={`${item.path}`} alt="icon" width="40" height="40" />
                      </IconButton>
                      <Typography align="center">
                        {`${item.name.slice(0, 5)}....${item.extension}`}
                      </Typography>
                    </Box>
                  );
                })()}
              </IconButton>
              <IconButton
                edge="start"
                color="inherit"
                disabled={tabName === 2}
                onClick={() => UnLinkedFile(data.id, item.id)}
                aria-label="close"
                sx={{ mb: 5, width: 10, height: 10 }}
              >
                <CloseIcon color="error" sx={{ fontSize: 20 }} />
              </IconButton>
            </Box>
          ))
          : (
            <Typography color="error" variant="caption" sx={{ mt: 1 }}>
              Henüz eklenmiş bir dosya yok.
            </Typography>
          )}

      </Box>
      <Box sx={{ marginTop: 2 }}>
        <Editor
          id="2"
          apiKey="9arf2f6sixoth7qu4kbfo8dgb4p7k7u3yxp8j7jz00vbeo25"
          onInit={(evt, editor) => editorRef.current = editor}
          initialValue={body}
          disabled={tabName === 2}
          init={{
            height: 500,
            menubar: true,
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount',
            ],
            toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
          {tabName === 0 && <Button onClick={createNews} loading={loading}>Ekle</Button>}
          {tabName === 1 && <Button color="warning" onClick={Update} loading={loading}>Güncelle</Button>}
          {tabName === 2 && <Button color="error" onClick={Delete} loading={loading}>Sil</Button>}

        </Box>
      </Box>

    </Box>
  );
}

export default NewsEditor;
