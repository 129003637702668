import config from '../config';
import { axiosCMSService } from './axios.config';

const endpoints = {
  create: 'menu/create',
  update: 'menu/update',
  delete: 'menu/delete',
  alllist: 'menu/alllist',
  getbyid: 'menu/getbyid',
  createsubmenu: 'menu/createsubmenu',
  updatesubmenu: 'menu/updatesubmenu',
  deletesubmenu: 'menu/deletesubmenu',
  getbyidsubmenu: 'menu/getbyidsubmenu',
  getbynamesubmenu: 'menu/getbynamesubmenu',
  getsubmenu: 'menu/getsubmenu',
  changerank: 'menu/changerank',

};

const MenuService = {
  async Create(data) {
    return axiosCMSService.post(endpoints.create, data);
  },
  async Update(data) {
    return axiosCMSService.post(endpoints.update, data);
  },
  async Delete(data) {
    return axiosCMSService.delete(`${endpoints.delete}?id=${data}`);
  },
  async Alllist(data) {
    return axiosCMSService.get(endpoints.alllist, data);
  },
  async GetById(data) {
    return axiosCMSService.get(endpoints.getbyid, data);
  },
  async GetByNameSubMenu(data) {
    return axiosCMSService.get(`${endpoints.getbynamesubmenu}?name=${data}`);
  },
  async Createsubmenu(data) {
    return axiosCMSService.post(endpoints.createsubmenu, data);
  },
  async Updatesubmenu(data) {
    return axiosCMSService.post(endpoints.updatesubmenu, data);
  },
  async Deletesubmenu(data) {
    return axiosCMSService.delete(`${endpoints.deletesubmenu}?id=${data}`);
  },
  async GetByIdsubmenu(data) {
    return axiosCMSService.get(endpoints.getbyidsubmenu, data);
  },
  async GetSubMenu(data) {
    return axiosCMSService.get(`${endpoints.getsubmenu}?id=${data}`);
  },
  async ChangeRank(data) {
    return axiosCMSService.post(endpoints.changerank, data);
  },
};

export default MenuService;
