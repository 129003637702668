import { axiosCMSService } from './axios.config';

const endpoints = {
  create: 'page/create',
  update: 'page/update',
  delete: 'page/delete',
  alllist: 'page/list',
  getbyid: 'page/getbyid',
  getlinkedsections: 'page/getlinkedsections',
  unlinksection: 'page/unlinksection',
  linksection: 'page/linksection',
  linknews: 'page/linknews',
  linkslider: 'page/linkslider',
  updatestatus: 'page/updatestatus',
  getpagecontent: 'Page/GetPageContent',
  unlinkslider: 'page/unlinkslider',
  unlinknews: 'page/unlinknews',
  getapprovelist: 'page/getapprovelist',
  updateapprove: 'page/updateapprove',
  getlinkednews: 'page/GetLinkedNews',
  getlinkedslider: 'page/getlinkedslider',

};

const PageService = {
  async Create(data) {
    return axiosCMSService.post(endpoints.create, data);
  },
  async LinkSection(data) {
    return axiosCMSService.post(endpoints.linksection, data);
  },
  async Update(data) {
    return axiosCMSService.post(endpoints.update, data);
  },
  async Delete(data) {
    return axiosCMSService.delete(endpoints.delete, data);
  },
  async Alllist() {
    return axiosCMSService.get(endpoints.alllist);
  },
  async GetById(data) {
    return axiosCMSService.get(`${endpoints.getbyid}?id=${data}`);
  },
  async GetLinkedSections(data) {
    return axiosCMSService.post(endpoints.getlinkedsections, data);
  },
  async GetLinkedNews(data) {
    return axiosCMSService.post(endpoints.getlinkednews, data);
  },
  async GetLinkedSlider(data) {
    return axiosCMSService.post(endpoints.getlinkedslider, data);
  },
  async UpdateStatus(id, status) {
    return axiosCMSService.get(`${endpoints.updatestatus}?id=${id}&status=${status}`);
  },
  async UnlinkSection(data) {
    return axiosCMSService.delete(endpoints.unlinksection, { data });
  },
  async GetPageContent(id) {
    return axiosCMSService.post(`${endpoints.getpagecontent}?id=${id}`);
  },
  async LinkNews(data) {
    return axiosCMSService.post(endpoints.linknews, data);
  },
  async UnlinkNews(data) {
    return axiosCMSService.delete(endpoints.unlinknews, { data });
  },
  async LinkSlider(data) {
    return axiosCMSService.post(endpoints.linkslider, data);
  },
  async UnlinkSlider(data) {
    return axiosCMSService.delete(endpoints.unlinkslider, { data });
  },
  async GetApproveList() {
    return axiosCMSService.get(endpoints.getapprovelist);
  },
  async UpdateApprove(status, data) {
    return axiosCMSService.post(`${endpoints.updateapprove}?status=${status}`, data);
  },

};
export default PageService;
