import { Autocomplete, Box, CardMedia, IconButton, TextField, Typography } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import SectionService from '../../services/sectionService';
import { Button } from '../../components/Button';
import { NotifyContext } from '../../contexts/NotifyContext';
import { DataContext } from '../../contexts/DataContext';
import FileService from '../../services/fileService';

const renderOption = (props, option) => (
  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
    <CardMedia
      component="img"
      height="50"
      image={option.path}
      style={{ width: '50px' }}
    />
    {option.name}
    .
    {option.extension}
  </Box>
);

function SectionItem({ open, tabName, onChange }) {
  const data = open?.data;
  const [name, setName] = React.useState(data?.name);
  const [body, setBody] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const { Notify } = React.useContext(NotifyContext);
  const { setSectionList, sectionList } = React.useContext(DataContext);
  const editorRef = React.useRef(0);
  const [fileList, setFileList] = React.useState([]);
  const [addItemFile, setAddItemFile] = React.useState([]);

  const getFiles = async () => {
    await FileService.AllList().then((response) => {
      setFileList(response?.data);
    });
  };
  const AddPdf = (pdf) => {
    const tempBody = editorRef.current.getContent() || '';
    const y = tempBody.concat(`<p><iframe src="${pdf}" width="100%" height="100%"></iframe></p>`);
    editorRef.current.setContent(y);
  };
  const AddImg = (img) => {
    const tempBody = editorRef.current.getContent() || '';
    const y = tempBody.concat(`<p><img src="${img}" width="100%" height="100%"></img></p>`);
    editorRef.current.setContent(y);
  };

  useEffect(() => {
    if (data && tabName !== 0) setBody(data?.body);
  }, [data]);

  const getAll = async () => {
    const result = await SectionService.AllList();
    setSectionList(result?.data);
    return result;
  };

  const Delete = async (id) => {
    const result = await SectionService.DeleteItem(id);
    editorRef.current.setContent('');
    getAll();
    if (onChange) {
      onChange();
    }
  };
  const LinkedFile = async (sectionItemId, fileId) => {
    if (fileId.length > 0) {
      await SectionService.LinkedFile({ sectionItemId, fileId }).then((res) => {
        if (!res?.data) {
          SectionService.GetById({ id: data?.id || data }).then((response) => {
            onChange({ data: response?.data, type: open?.type });
          });
        }
      });
    }
  };
  const Update = async () => {
    if (data.id) {
      const result = await SectionService.UpdateItem({
        id: data.id,
        name,
        body: editorRef.current.getContent(),
      });
      getAll();
      if (onChange) {
        onChange();
      }
      return result;
    }
    Notify('Tüm alanları doldurunuz', 'error');
  };
  const createSectionItem = async () => {
    if (name) {
      await SectionService.CreateSectionItem({
        name,
        body: editorRef.current.getContent(),
        sectionId: data,
      }).then(async (response) => {
        if (!response.error) {
          LinkedFile(response.data.id, addItemFile.map((item) => item.id));
          getAll();
          await SectionService.GetById({ id: response.data.id }).then((res) => {
            onChange({ data: res?.data, type: 'createsectionitem' });
          });
        }
      });
    } else {
      Notify('Eksik veya boş içerik gönderilemez!', 'error');
      setLoading(false);
    }
  };
  const UnLinkedFile = async (sectionItemId, fileId) => {
    if (tabName === 0) {
      const tempFiles = [...addItemFile];
      const deleteItemIndex = tempFiles.findIndex((item) => item.id === fileId);
      tempFiles.splice(deleteItemIndex, 1);
      setAddItemFile(tempFiles);
    } else {
      await SectionService.UnLinkedFile({ sectionItemId, fileId }).then((res) => {
        if (!res?.error) {
          SectionService.GetById({ id: data.id }).then((response) => {
            onChange({ data: response?.data, type: open?.type });
          });
        }
      });
    }
  };

  const handleTextField = (event) => {
    setName(event.target.value);
  };
  const UploadFile = async (uploadFile, isSetBody) => {
    if (uploadFile) {
      const file = new FormData();
      file.append('Files', uploadFile);
      if (tabName !== 0) file.append('SectionItemId', data.id);
      await FileService.Upload(file).then((res) => {
        if (!res?.error) {
          getFiles();
          if (tabName === 0) {
            const tempFiles = [...addItemFile];
            tempFiles.push(res.data);
            setAddItemFile(tempFiles);
          } else {
            LinkedFile(data.id, [res.data.id]);
            SectionService.GetById({ id: data.id }).then((response) => {
              onChange({ data: response?.data, type: open?.type });
            });
          }
        }
      });
    }
  };
  useEffect(() => {
    getFiles();
  }, []);
  return (
    <>
      {(tabName === 0 || tabName === 1) && <TextField fullWidth id="outlined-basic" label="Eleman İsmi" variant="outlined" value={name} onChange={handleTextField} />}
      {tabName === 2 && <Typography color="red" variant="body1">Bu içeriği silmek isteğine emin misin ?</Typography>}
      {tabName !== 2 && (
      <>
        <TextField
          fullWidth
          id="outlined-basic"
          sx={{ mt: 2 }}
          variant="outlined"
          type="file"
          draggable
          onChange={(e) => (UploadFile(e.target.files[0]))}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', columnGap: 5, mt: 2 }}>
          <Autocomplete
            fullWidth
            options={fileList}
            getOptionLabel={(option) => (option ? `${option.name}.${option.extension}` : '')}
            renderOption={(props, option) => renderOption(props, option)}
            onChange={(event, value) => {
              if (value) {
                if (tabName === 0) {
                  const tempFiles = [...addItemFile];
                  tempFiles.push(value);
                  setAddItemFile(tempFiles);
                } else {
                  LinkedFile(data.id, [value.id]);
                }
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Sunucudan Dosya Seç"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
        </Box>
      </>
      )}
      <Typography
        color="primary"
        variant="body1"
        sx={{ mt: 3 }}
      >
        Bölüme ait dosyalar:
      </Typography>
      <Typography
        color="orange"
        variant="body2"
      >
        Pdf ve fotoğrafların üzerine tıklayarak editöre ekleyebilirsiniz. Zip dosyalarına tıklayarak indirebilirsiniz.
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {(tabName === 0 ? addItemFile.length : data?.files?.length) ? (
          (tabName === 0 ? addItemFile : data?.files)?.map((item) => (
            <Box key={item.id}>
              <IconButton>
                {(() => {
                  if (item.extension === 'pdf') {
                    return (
                      <Box sx={{ flexDirection: 'column' }}>
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={() => {
                            AddPdf(item.path);
                          }}
                          aria-label="close"
                          sx={{}}
                        >
                          <PictureAsPdfIcon sx={{ fontSize: 40 }} />
                        </IconButton>
                        <Typography align="center">
                          {`${item.name.slice(0, 5)}....${item.extension}`}
                        </Typography>
                      </Box>
                    );
                  }
                  if (item.extension === 'zip') {
                    return (
                      <Box sx={{ flexDirection: 'column' }}>
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={() => window.open(`${item.path}`, '_blank')}
                          aria-label="close"
                        >
                          <FolderZipIcon color="primary" sx={{ fontSize: 40 }} />
                        </IconButton>
                        <Typography align="center">
                          {`${item.name.slice(0, 5)}....${item.extension}`}
                        </Typography>
                      </Box>
                    );
                  }

                  return (
                    <Box sx={{ flexDirection: 'column' }}>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => {
                          AddImg(item.path);
                        }}
                        aria-label="close"
                        sx={{}}
                      >
                        <img src={`${item.path}`} width="40" height="40" alt="close" />
                      </IconButton>
                      <Typography align="center">
                        {`${item.name.slice(0, 5)}....${item.extension}`}
                      </Typography>
                    </Box>
                  );
                })()}
              </IconButton>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => UnLinkedFile(data?.id, item.id)}
                disabled={tabName === 2}
                aria-label="close"
                sx={{ mb: 5, width: 10, height: 10 }}
              >
                <CloseIcon color="error" sx={{ fontSize: 20 }} />
              </IconButton>
            </Box>
          ))
        ) : (
          <Typography color="error" variant="caption" sx={{ mt: 1 }}>
            Henüz eklenmiş bir dosya yok.
          </Typography>
        )}
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <Editor
          id="2"
          apiKey="9arf2f6sixoth7qu4kbfo8dgb4p7k7u3yxp8j7jz00vbeo25"
          onInit={(evt, editor) => editorRef.current = editor}
          initialValue={body}
          disabled={tabName === 2}
          init={{
            height: 500,
            menubar: true,
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount',
            ],
            toolbar: 'undo redo | blocks | ' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          }}
        />

        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
          {tabName === 0 && <Button onClick={createSectionItem} loading={loading}>Ekle</Button>}
          {tabName === 1 && <Button color="warning" onClick={Update} loading={loading}>Güncelle</Button>}
          {tabName === 2 && <Button color="error" onClick={() => Delete(data.id)} loading={loading}>Sil</Button>}
        </Box>
      </Box>
    </>
  );
}

export default SectionItem;
