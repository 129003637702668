import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogActions, Button, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function DeleteDialog({ open, onClose, onConfirm }) {
  return (
    <Dialog disableEnforceFocus open={open} onClose={onClose}>
      <AppBar sx={{ position: 'relative', backgroundColor: 'error.main' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Silme İşlemi
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogTitle variant="body1">
        Silme işlemini yapmak üzeresin.
        <br />
        {' '}
        Devam etmek istiyor musun?
        {' '}
      </DialogTitle>
      <DialogActions>
        <Button onClick={onClose}>Hayır</Button>
        <Button onClick={onConfirm}>Evet</Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default DeleteDialog;
