import { Button, Card, CardActionArea, CardContent, CardHeader, CardMedia, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import TextRotateUpIcon from '@mui/icons-material/TextRotateUp';
import { Link } from 'react-router-dom';
import { loaderRef } from '../../components/Loader';
import { groupBy } from '../../utilities/helpers';
import PageService from '../../services/pageService';
import { DataContext } from '../../contexts/DataContext';

const { Box } = require('@mui/system');

function Page() {
  const [pageList, setPageList] = useState();
  const [filter, setFilter] = useState('menuItemName');
  const [filterInput, setFilterInput] = useState('');

  const [orientation, setOrientation] = useState(4);
  const { setSelectedIndex } = useContext(DataContext);

  const getPageList = useCallback(async () => {
    const result = await PageService.Alllist();
    if (filter === 'menuItemName') setPageList(Object.entries(groupBy(result?.data, filter)).sort());
    else {
      setPageList(Object.entries(groupBy(result?.data, filter)).sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()));
    }
    return result;
  }, [filter]);

  useEffect(() => {
    getPageList();
  }, [getPageList]);

  useLayoutEffect(
    () => {
      setSelectedIndex(13);
    },
    [],
  );
  return (
    <Grid2 container sx={{ backgroundColor: 'grey.50' }} borderRadius="10px" padding={1} spacing={2}>
      <Grid2 item xs={12}>
        <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 1, alignItems: 'flex-end' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', columnGap: 1 }}>
            <Typography variant="caption" sx={{ color: 'text.primary' }}>Sıralama </Typography>
            <ToggleButtonGroup
              value={filter}
              exclusive
              onChange={(x, nextView) => { nextView !== null && setFilter(nextView); }}
            >
              <ToggleButton sx={{ textTransform: 'none', columnGap: '5px' }} value="menuItemName">
                <TextRotateUpIcon sx={{ fontSize: 18 }} />
                İsim
              </ToggleButton>
              <ToggleButton sx={{ textTransform: 'none', columnGap: '5px' }} value="lastUpdatedDate">
                <EditCalendarIcon sx={{ fontSize: 18 }} />
                Tarih
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', columnGap: 1, flex: 1 }}>
            <TextField
              id="outlined-basic"
              label="Arama"
              variant="standard"
              onChange={(e) => setFilterInput(e.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', columnGap: 1, alignItems: 'flex-end' }}>
            <Typography variant="caption" sx={{ color: 'text.primary' }}> </Typography>
            <ToggleButtonGroup
              value={orientation}
              exclusive
              onChange={(x, nextView) => { nextView !== null && setOrientation(nextView); }}
            >
              <ToggleButton value={12}>
                <ViewListIcon />
              </ToggleButton>
              <ToggleButton value={4}>
                <ViewModuleIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
      </Grid2>
      {orientation === 4 ?
        (pageList?.map((x) => (filterInput ? [x[0], x[1].filter((y) => (y.subMenuItemName || y.menuItemName)?.toLowerCase().includes(filterInput?.toLowerCase()))] : x))?.map((y, index) => (
          <>
            { !!y[1]?.[0] && (
            <Grid2 sx={{ backgroundColor: '#1565c0', padding: '1rem' }} xs={12} lg={12} item>
              <Typography variant="h6" color="white" fontWeight="500">{y[1]?.[0]?.menuItemName}</Typography>
            </Grid2>
            )}
            <React.Fragment key={index}>

              {y[1]?.map((x) => (
                <Grid2
                  item
                  lg={orientation}
                  md={orientation}
                  xs={orientation * 3}
                  key={x.createdDate}
                >
                  <Card variant="elevation">
                    <CardActionArea disableRipple>
                      <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 2 }}>
                        <Typography variant="h6">
                          {x.subMenuItemName || x.menuItemName}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                          <Typography variant="body2" color="text.secondary">
                            {moment(x.lastUpdatedDate).format('DD.MM.YYYY HH:MM')}
                          </Typography>
                          <Typography color={x.isApprove ? 'green' : 'red'} variant="body2">
                            {x.isApprove ? 'Onaylandı' : 'Onay Bekliyor'}
                          </Typography>
                        </Box>
                      </Box>
                      <CardMedia
                        component="img"
                        height="140"
                        image="logo.svg"
                      />
                      <CardContent>

                        <Link
                          onClick={() => loaderRef.current.incLoader()}
                          to={`/page/detail/detail/${x.id}`}
                        >
                          <Box sx={{ background: '#1565c0', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '35px' }}>
                            <Typography variant="button" color="white" textAlign="center">DÜZENLE</Typography>
                          </Box>
                        </Link>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid2>
              ))}
            </React.Fragment>
          </>
        ))) :
        (pageList?.map((x) => (filterInput ? [x[0], x[1].filter((y) => (y.subMenuItemName || y.menuItemName)?.toLowerCase().includes(filterInput?.toLowerCase()))] : x))?.map((y, index) => (
          <>
            { !!y[1]?.[0] && (
            <Grid2 sx={{ backgroundColor: '#1565c0', padding: '1rem' }} xs={12} lg={12} item>
              <Typography variant="h6" color="white" fontWeight="500">{y[1]?.[0]?.menuItemName}</Typography>
            </Grid2>
            )}
            <Grid2 key={index} container md={orientation} xs={orientation * 3}>
              {y[1]?.map((x) => (
                <Grid2
                  item
                  lg={12}
                  xs={12}
                  key={x.createdDate}
                >

                  <Link
                    onClick={() => loaderRef.current.incLoader()}
                    to={`/page/detail/detail/${x.id}`}

                  >
                    <Card sx={{ display: 'flex' }} variant="elevation">
                      <CardActionArea sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'normal' }}>
                        <Box display={{ xs: 'none', md: 'block', lg: 'block' }}>
                          <CardMedia
                            component="img"
                            height="50"
                            width="100"
                            sx={{ objectFit: 'fill', width: 100, height: 50, marginLeft: 2, marginRight: 2, borderRadius: 1 }}
                            image="logo.svg"
                          />
                        </Box>
                        <Box
                          sx={{ display: 'flex', flexDirection: 'row', flex: 1 }}
                        >
                          <CardHeader
                            sx={{ flex: 0.5 }}
                            title={x.subMenuItemName || x.menuItemName}
                            titleTypographyProps={{ variant: 'h6' }}
                          />
                          <CardContent sx={{ flex: 1 }} />
                          <Button variant="contained" type="reset" sx={{ height: '40px', alignSelf: 'center' }}>Düzenle</Button>

                          <CardHeader
                            sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' }}
                            subheader={moment(x.lastUpdatedDate).format('dddd MM, YYYY HH:MM')}
                            subheaderTypographyProps={{ variant: 'body2' }}
                          />
                        </Box>

                      </CardActionArea>
                    </Card>
                  </Link>
                </Grid2>
              ))}
            </Grid2>
          </>
        )))
      }
    </Grid2>

  );
}

export default Page;
