import { AppBar, Box, Button, Card, CardContent, CardMedia, Dialog, DialogActions, DialogContent, Autocomplete, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, Tab, Tabs, TextField, Typography, Input } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import SliderService from '../../services/sliderService';
import PageService from '../../services/pageService';
import FileService from '../../services/fileService';
import { NotifyContext } from '../../contexts/NotifyContext';
import AntSwitch from '../../components/Switch';
import DeleteDialog from '../../components/DeleteDialog';
import { ApprovedStatus } from '../../constants/enum';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export default function Slider() {
  const [sliders, setSliders] = useState();
  const [selectedPage, setSelectedPage] = useState(0);
  const [pageList, setPageList] = useState([]);
  const [pageId, setPageId] = useState();
  const [addRequest, setAddRequest] = useState({ pageid: null, webImageUrl: null, mobileImageUrl: null, title: null });
  const [updateRequest, setUpdateRequest] = useState({ webImageUrl: null, mobileImageUrl: null, title: null, status: true, id: 0 });
  const [files, setFiles] = useState([]);

  const { Notify } = useContext(NotifyContext);
  const [open, setOpen] = useState(false);
  const [deleteEvents, setDeleteEvents] = useState({ open: false, itemId: 0 });

  const [uploadFile, setUploadFile] = useState();
  const getFiles = async () => {
    await FileService.ImageList().then((response) => {
      setFiles(response?.data);
    });
  };
  const getPage = async () => {
    const result = await PageService.Alllist();
    setPageList(result?.data);
    return result;
  };

  const UploadFile = async (data) => {
    const file = new FormData();
    file.append('Files', data);
    await FileService.Upload(file).then((response) => {
      if (!response?.error) getFiles();
    });
  };

  const AllList = async () => {
    await SliderService.AllList(false).then((response) => {
      setSliders(response?.data);
    });
  };
  const DeleteSlider = async (id) => {
    const result = await SliderService.Delete(id).then(() => {
      setDeleteEvents({ open: false, itemId: 0 });
    });
    AllList();
  };
  const UpdateSlider = async () => {
    await SliderService.Update(updateRequest).then((response) => {
      if (!response?.error) {
        AllList();
        setOpen(false);
      }
    });
  };
  const handleChange = (event, newValue) => {
    setSelectedPage(newValue);
  };
  const AddSlider = async () => {
    if (addRequest.mobileImageUrl !== null && addRequest.pageid !== null && addRequest.title !== null && addRequest.webImageUrl !== null) {
      await SliderService.Create(addRequest);
      AllList();
    } else Notify('Tüm alanlar zorunludur', 'error');
  };
  const handleChangePage = async (event) => {
    setAddRequest({ ...addRequest, pageid: event.target.value });
  };
  const handleTitleChange = async (event) => {
    setAddRequest({ ...addRequest, title: event.target.value });
  };
  const handleWebUrlChange = async (value) => {
    setAddRequest({ ...addRequest, webImageUrl: value?.path });
  };
  const handleMobileUrlChange = async (value) => {
    setAddRequest({ ...addRequest, mobileImageUrl: value?.path });
  };

  const renderOption = (props, option) => (
    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
      <CardMedia
        component="img"
        height="50"
        image={option.path}
        style={{ width: '50px' }}
      />
      {option.name}
      .
      {option.extension}
    </Box>
  );

  useEffect(() => {
    AllList();
    getPage();
    getFiles();
  }, []);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <AppBar position="static">
          <Tabs
            value={selectedPage}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Slider Ekle" {...a11yProps(0)} />
            <Tab label="Slider Güncelle veya Sil" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <CustomTabPanel value={selectedPage} index={0}>
          <Box sx={{ m: 5, display: 'flex', columnGap: 5 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Sayfa</InputLabel>
              <Select
                defaultValue=""
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={addRequest.pageid || ''}
                label="Page"
                onChange={handleChangePage}
              >

                {pageList.map((item) => (
                  <MenuItem key={item?.id} value={item?.id}>{item?.subMenuItemName === null ? `${item?.menuItemName}` : `${item?.subMenuItemName}`}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField label="Title" onChange={handleTitleChange} fullWidth />
          </Box>
          <Box sx={{ m: 5, display: 'flex', columnGap: 5 }}>
            <Autocomplete
              options={files}
              fullWidth
              getOptionLabel={(option) => (option ? `${option.name}.${option.extension}` : '')}
              renderOption={(props, option) => renderOption(props, option)}
              onChange={(event, value) => handleWebUrlChange(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Web Image Url"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
            <Autocomplete
              options={files}
              fullWidth
              getOptionLabel={(option) => (option ? `${option.name}.${option.extension}` : '')}
              renderOption={(props, option) => renderOption(props, option)}
              onChange={(event, value) => handleMobileUrlChange(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Mobile Image Url"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          </Box>
          <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
            <Button onClick={AddSlider} variant="contained">Ekle</Button>
          </Box>
          <Box fullWidth sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 3, flexDirection: 'column' }}>
            <Typography>Eklemek istediğiniz dosya listelenmiyorsa ilk önce sunucuya yüklemen gerekir.</Typography>
            <TextField id="outlined-basic" variant="outlined" type="file" draggable onChange={(e) => setUploadFile(e.target.files[0])} />
            <Button onClick={() => UploadFile(uploadFile)} sx={{ m: 2 }} variant="contained">Dosyayı Sunucuya Yükle</Button>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={selectedPage} index={1}>
          {sliders?.map((item) => (
            <Card key={item.id} elevation={20} sx={{ color: 'white', backgroundColor: 'primary.light', flexDirection: 'row', display: 'flex', marginTop: 5 }}>
              <CardMedia
                component="img"
                height="275"
                image={item.webImageUrl}
              />
              <CardContent sx={{ width: '25%' }}>
                <Typography sx={{ flex: 1 }} variant="h4" component="div">
                  {item.title}
                </Typography>
                <Typography variant="body2">
                  {`Oluşturma tarihi: ${moment(item.createdDate).format('DD/MM/YYYY HH:mm')}`}
                </Typography>
                <Typography variant="body2">
                  {item.updateDate ? `Güncelleme tarihi: ${moment(item.updateDate).format('DD/MM/YYYY HH:mm')}` : ''}
                </Typography>
                <Typography>{item.isApprove === ApprovedStatus.Confirm ? 'Onaylanmış' : 'Onaysız'}</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 1 }}>
                  <Button color="error" onClick={() => setDeleteEvents({ open: true, itemId: item.id })} variant="contained">Sil</Button>
                  <Button variant="contained" color="warning" onClick={() => { setUpdateRequest({ ...updateRequest, mobileImageUrl: item.mobileImageUrl, webImageUrl: item.webImageUrl, title: item.title, status: item.status, id: item.id }); setOpen(true); }}>Güncelle</Button>
                </Box>
              </CardContent>
            </Card>
          ))}
        </CustomTabPanel>
      </Box>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Slider Güncelle</DialogTitle>
        <DialogContent>
          <TextField
            defaultValue={updateRequest.title}
            autoFocus
            margin="dense"
            id="name"
            label="Başlık"
            fullWidth
            variant="outlined"
            onChange={(event) => setUpdateRequest({ ...updateRequest, title: event.target.value })}
          />
          <FormControl margin="dense" fullWidth>
            <Autocomplete
              value={files.find((item) => item.path === updateRequest.webImageUrl) ?? null}
              options={files}
              fullWidth
              getOptionLabel={(option) => (option ? `${option.name}.${option.extension}` : '')}
              renderOption={(props, option) => renderOption(props, option)}
              onChange={(event, value) => setUpdateRequest({ ...updateRequest, webImageUrl: value?.path })}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Web Fotoğrafı Url"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl margin="dense" fullWidth>
            <Autocomplete
              value={files.find((item) => item.path === updateRequest.mobileImageUrl) ?? null}
              options={files}
              fullWidth
              getOptionLabel={(option) => (option ? `${option.name}.${option.extension}` : '')}
              renderOption={(props, option) => renderOption(props, option)}
              onChange={(event, value) => setUpdateRequest({ ...updateRequest, mobileImageUrl: value?.path })}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Mobil Fotoğrafı Url"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          </FormControl>
          <TextField fullWidth sx={{ mt: 1 }} id="outlined-basic" variant="outlined" type="file" draggable onChange={(e) => UploadFile(e.target.files[0])} />
          <FormControlLabel
            control={<AntSwitch checked={updateRequest.status} onChange={(e) => setUpdateRequest({ ...updateRequest, status: e.target.checked })} />}
            slotProps={{ typography: { fontSize: 12 } }}
            label="Durum"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={UpdateSlider}>Güncelle</Button>
        </DialogActions>
      </Dialog>
      <DeleteDialog
        open={deleteEvents.open}
        onClose={() => setDeleteEvents({ ...deleteEvents, open: false })}
        onConfirm={() => DeleteSlider(deleteEvents.itemId)}
      />
    </>
  );
}
