import { AppBar, Autocomplete, Button, CardMedia, Dialog, DialogContent, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Toolbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import React, { useState, useEffect, useContext } from 'react';
import FileService from '../../../../services/fileService';
import { NotifyContext } from '../../../../contexts/NotifyContext';
import SliderService from '../../../../services/sliderService';

function AddDialog({ open, setOpen, onChange }) {
  const [addRequest, setAddRequest] = useState();
  const [files, setFiles] = useState([]);
  const { Notify } = useContext(NotifyContext);

  const AddSlider = async () => {
    if (addRequest.mobileImageUrl !== null && addRequest.pageid !== null && addRequest.title !== null && addRequest.webImageUrl !== null) {
      await SliderService.Create(addRequest);
      onChange();
    } else Notify('Tüm alanlar zorunludur', 'error');
  };

  const getFiles = async () => {
    await FileService.ImageList().then((response) => {
      setFiles(response?.data);
    });
  };

  const UploadFile = async (data) => {
    const file = new FormData();
    file.append('Files', data);
    await FileService.Upload(file).then((response) => {
      if (!response?.error) getFiles();
    });
  };
  const handleChangePage = async (event) => {
    setAddRequest({ ...addRequest, pageid: event.target.value });
  };
  const handleTitleChange = async (event) => {
    setAddRequest({ ...addRequest, title: event.target.value });
  };
  const handleWebUrlChange = async (value) => {
    setAddRequest({ ...addRequest, webImageUrl: value?.path });
  };
  const handleMobileUrlChange = async (value) => {
    setAddRequest({ ...addRequest, mobileImageUrl: value?.path });
  };
  const renderOption = (props, option) => (
    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
      <CardMedia
        component="img"
        height="50"
        image={option.path}
        style={{ width: '50px' }}
      />
      {option.name}
      .
      {option.extension}
    </Box>
  );

  useEffect(() => {
    getFiles();
    setAddRequest({ pageid: open?.pageId, webImageUrl: null, mobileImageUrl: null, title: null });
  }, [open]);
  return (
    <Dialog disableEnforceFocus fullWidth maxWidth="100%" open={open} onClose={() => setOpen(!open)}>
      <AppBar sx={{ position: 'relative', backgroundColor: 'primary' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen()}
            aria-label="close"
          >
            <AddIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Slider Ekle
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box sx={{ m: 5, display: 'flex', columnGap: 5 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Sayfa</InputLabel>
            <Select
              defaultValue={open?.pageId}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={addRequest?.pageid || ''}
              label="Page"
              onChange={handleChangePage}
            >
              <MenuItem key={open?.pageId} value={open?.pageId}>{open?.subMenuItemName === '' ? `${open?.menuItemName}` : `${open?.subMenuItemName}`}</MenuItem>
            </Select>
          </FormControl>
          <TextField label="Title" onChange={handleTitleChange} fullWidth />
        </Box>
        <Box sx={{ m: 5, display: 'flex', columnGap: 5 }}>
          <TextField fullWidth id="outlined-basic" variant="outlined" type="file" draggable onChange={(e) => UploadFile(e.target.files[0])} />
          <Autocomplete
            options={files}
            fullWidth
            getOptionLabel={(option) => (option ? `${option.name}.${option.extension}` : '')}
            renderOption={(props, option) => renderOption(props, option)}
            onChange={(event, value) => handleWebUrlChange(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Web Image Url"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />
          <Autocomplete
            options={files}
            fullWidth
            getOptionLabel={(option) => (option ? `${option.name}.${option.extension}` : '')}
            renderOption={(props, option) => renderOption(props, option)}
            onChange={(event, value) => handleMobileUrlChange(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Mobile Image Url"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />
        </Box>
        <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
          <Button onClick={AddSlider} variant="contained">Ekle</Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
export default AddDialog;
