import { useNavigate } from 'react-router-dom';
import { cookies } from '../App';
import UserService from '../services/userService';
import { ButtonLoaderContext } from './ButtonLoaderContext';
import { NotifyContext } from './NotifyContext';
import { axiosCMSService } from '../services/axios.config';

const { createContext, useState, useMemo, useContext, useEffect } = require('react');

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [user, setUser] = useState(() => {
    const userData = localStorage.getItem('user');
    return userData ? JSON.parse(userData) : null;
  });
  const { Notify } = useContext(NotifyContext);
  const navigate = useNavigate();
  const { setLoading } = useContext(ButtonLoaderContext);

  const login = async (email, password) => {
    await UserService.Login({
      email,
      password,
    }).then((result) => {
      Notify(result);
      setUser(result?.data || '');
      cookies.set('user', result?.data || '');
      axiosCMSService.defaults.headers.common.Authorization = `Bearer ${result?.data?.accessToken}`;
      localStorage.setItem('user', JSON.stringify(result?.data || ''));
      if (result?.error === false) {
        if (result?.data.roleName === 'Admin') {
          navigate('/admin');
        } else {
          navigate('/menu');
        }
      }
      setLoading(false);
      return true;
    });
  };
  const logout = () => {
    localStorage.removeItem('user');
    cookies.remove('user');
    navigate('/login');
    setUser(null);
  };

  const contextValue = useMemo(() => (
    {
      login,
      user,
      logout,
    }
  ), [user]);

  useEffect(() => {
    if (user !== '') {
      const userData = localStorage.getItem('user');
      setUser(userData ? JSON.parse(userData) : null);
    }
  }, []);
  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
