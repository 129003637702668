import { Box, Paper, Typography } from '@mui/material';
import React, { useEffect, useContext, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { TreeView } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import { DataContext } from '../../contexts/DataContext';
import { Button } from '../../components/Button';
import SectionService from '../../services/sectionService';
import FormDialog from './Dialog';
import StyledTreeItem from '../../components/CustomTreeItem';
import DeleteDialog from '../../components/DeleteDialog';
import { NotifyContext } from '../../contexts/NotifyContext';

/*
  ekle-> name
  güncelle -> id
  sil -> id
*/

function Section() {
  const [dialog, setDialog] = React.useState();
  const { Notify } = useContext(NotifyContext);
  const { sectionList, setSectionList } = useContext(DataContext);
  const [deleteEvents, setDeleteEvents] = useState({ open: false, itemId: 0 });

  const AllSection = async () => {
    const result = await SectionService.AllList();
    setSectionList(result?.data);
  };

  const DeleteSection = async (id) => {
    const result = await SectionService.Delete(id).then(() => {
      setDeleteEvents({ open: false, itemId: 0 });
    });
    AllSection();
  };

  useEffect(() => {
    AllSection();
  }, [dialog]);
  return (
    <>
      <Paper sx={{ backgroundColor: 'grey.50', padding: 1, borderRadius: '10px' }}>
        <Box sx={{ marginBottom: 5, display: 'flex', justifyContent: 'flex-end' }}>
          <Button color="warning" variant="text" sx={{ alignSelf: 'stretch' }} onClick={() => setDialog({ data: {}, type: 'createsection' })}>
            <Typography variant="overline">
              Bölüm Ekle
            </Typography>
            <AddIcon fontSize="14" />
          </Button>
        </Box>
        <TreeView
          aria-label="gmail"
          defaultCollapseIcon={<RemoveIcon sx={{ color: 'white', alignSelf: 'baseline', backgroundColor: 'primary.main', borderRadius: '3px' }} />}
          defaultExpandIcon={<AddIcon sx={{ color: 'white', alignSelf: 'baseline', backgroundColor: 'primary.main', borderRadius: '3px' }} />}
          defaultEndIcon={<div style={{ width: 24 }} />}
        >
          {sectionList?.map((x) => (
            <Box key={x.id}>
              <StyledTreeItem
                color="#1a73e8"
                bgColor="#e8f0fe"
                nodeId={x.id}
                labelText={x.name}
                  // onClick={()=>setSectionId(x.id)}
                labelInfo={(
                  <Box>
                    { x.sectionItems && (
                      <Button
                        onClick={() => setDialog({ data: x.id, type: 'createsectionitem' })}
                        variant="text"
                        startIcon={<AddIcon style={{ fontSize: 12 }} />}
                        style={{ textTransform: 'none' }}
                        sx={{ mb: 0, mt: 0, marginRight: 2, fontSize: 12 }}
                      >
                        Eleman Ekle
                      </Button>
                    ) }
                    <Button
                      onClick={() => setDialog({ data: x, type: 'editsection' })}
                      variant="text"
                      color="warning"
                      // onClick={() => deleteMenu(x.id)}
                      startIcon={<EditIcon style={{ fontSize: 12 }} />}
                      style={{ textTransform: 'none' }}
                      sx={{ mb: 0.5, mt: 0.5, mr: 2, fontSize: 12 }}
                    >
                      Düzenle
                    </Button>
                    <Button
                      onClick={() => setDeleteEvents({ open: true, itemId: x.id })}
                      variant="text"
                      color="error"
                      // onClick={() => deleteMenu(x.id)}
                      startIcon={<DeleteIcon style={{ fontSize: 12 }} />}
                      style={{ textTransform: 'none' }}
                      sx={{ mb: 0.5, mt: 0.5, mr: 2, fontSize: 12 }}
                    >
                      Sil
                    </Button>
                  </Box>
)}
              >
                {x.sectionItems.map((y) => (
                  <StyledTreeItem
                    color="#e3742f"
                    bgColor="#fcefe3"
                    key={y.createdDate}
                    labelColor={y.isApprove ? 'black' : 'red'}
                    nodeId={y.createdDate}
                    labelText={y.name}
                    labelInfo={(
                      <Box>
                        <Button
                          onClick={() => setDialog({ data: y, type: 'editsectionitem' })}
                          variant="text"
                          color="warning"
                          startIcon={<EditIcon style={{ fontSize: 12 }} />}
                          style={{ textTransform: 'none' }}
                          sx={{ mb: 0, mt: 0, marginRight: 2, fontSize: 12 }}
                        >
                          Düzenle
                        </Button>
                        <Button
                          onClick={() => setDialog({ data: y, type: 'deletesectionitem' })}
                          variant="text"
                          color="error"
                          startIcon={<DeleteIcon style={{ fontSize: 12 }} />}
                          style={{ textTransform: 'none' }}
                          sx={{ mb: 0.5, mt: 0.5, mr: 2, fontSize: 12 }}
                        >
                          Sil
                        </Button>
                      </Box>
)}
                  />
                ))}
              </StyledTreeItem>
            </Box>
          ))}
        </TreeView>
      </Paper>
      <FormDialog open={dialog} setOpen={setDialog} />
      <DeleteDialog
        open={deleteEvents.open}
        onClose={() => setDeleteEvents({ ...deleteEvents, open: false })}
        onConfirm={() => DeleteSection(deleteEvents.itemId)}
      />
    </>
  );
}

export default Section;
