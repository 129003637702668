import { axiosCMSService } from './axios.config';

const endpoints = {
  allList: 'file/list',
  imageList: 'file/imageList',
  upload: 'file/upload',
  delete: 'file/delete',
};

const FileService = {
  async AllList() {
    return axiosCMSService.get(endpoints.allList);
  },
  async ImageList() {
    return axiosCMSService.get(endpoints.imageList);
  },
  async Upload(data) {
    return axiosCMSService.post(endpoints.upload, data, { headers: { 'Content-Type': 'multipart/form-data' } });
  },
  async Delete(id) {
    return axiosCMSService.delete(`${endpoints.delete}?id=${id}`);
  },
};

export default FileService;
