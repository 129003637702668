export const Fetch = (url, requestOptions) => (
  fetch(url, { ...requestOptions })
    .then(async (response) => response?.json())
    .catch((e) => console.error(e)));

export const groupBy = function (xs, key) {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const gridLocalize = {

  // Root
  noRowsLabel: 'Kayıt bulunamadı',
  noResultsOverlayLabel: 'Sonuç bulunamadı.',

  // Density selector toolbar button text
  toolbarDensity: 'Yoğunluk',
  toolbarDensityLabel: 'Yoğunluk',
  toolbarDensityCompact: 'Sıkı',
  toolbarDensityStandard: 'Standart',
  toolbarDensityComfortable: 'Rahat',

  // Columns selector toolbar button text
  toolbarColumns: 'Sütunlar',
  toolbarColumnsLabel: 'Sütunları Seç',

  // Filters toolbar button text
  toolbarFilters: 'Filtreler',
  toolbarFiltersLabel: 'Filtreleri Göster',
  toolbarFiltersTooltipHide: 'Filtreleri Gizle',
  toolbarFiltersTooltipShow: 'Filtreleri Göster',
  toolbarFiltersTooltipActive: (count) =>
    (count !== 1 ? `${count} etkin filtre` : `${count} etkin filtre`),

  // Quick filter toolbar field
  toolbarQuickFilterPlaceholder: 'Ara…',
  toolbarQuickFilterLabel: 'Ara',
  toolbarQuickFilterDeleteIconLabel: 'Temizle',

  // Export selector toolbar button text
  toolbarExport: 'Dışa Aktar',
  toolbarExportLabel: 'Dışa Aktar',
  toolbarExportCSV: 'CSV Olarak İndir',
  toolbarExportPrint: 'Yazdır',
  toolbarExportExcel: 'Excel Olarak İndir',

  // Columns panel text
  columnsPanelTextFieldLabel: 'Sütun Bul',
  columnsPanelTextFieldPlaceholder: 'Sütun başlığı',
  columnsPanelDragIconLabel: 'Sütunu Yeniden Sırala',
  columnsPanelShowAllButton: 'Tümünü Göster',
  columnsPanelHideAllButton: 'Tümünü Gizle',

  // Filter panel text
  filterPanelAddFilter: 'Filtre Ekle',
  filterPanelRemoveAll: 'Tümünü Kaldır',
  filterPanelDeleteIconLabel: 'Sil',
  filterPanelLogicOperator: 'Mantık Operatörü',
  filterPanelOperator: 'Operatör',
  filterPanelOperatorAnd: 'Ve',
  filterPanelOperatorOr: 'Veya',
  filterPanelColumns: 'Sütunlar',
  filterPanelInputLabel: 'Değer',
  filterPanelInputPlaceholder: 'Filtre değeri',

  // Filter operators text
  filterOperatorContains: 'içerir',
  filterOperatorEquals: 'eşittir',
  filterOperatorStartsWith: 'ile başlar',
  filterOperatorEndsWith: 'ile biter',
  filterOperatorIs: 'dir',
  filterOperatorNot: 'değildir',
  filterOperatorAfter: 'sonradan',
  filterOperatorOnOrAfter: 'veya sonrasında',
  filterOperatorBefore: 'önceden',
  filterOperatorOnOrBefore: 'veya öncesinde',
  filterOperatorIsEmpty: 'boştur',
  filterOperatorIsNotEmpty: 'boş değildir',
  filterOperatorIsAnyOf: 'herhangi biri',
  'filterOperator=': '=',
  'filterOperator!=': '!=',
  'filterOperator>': '>',
  'filterOperator>=': '>=',
  'filterOperator<': '<',
  'filterOperator<=': '<=',

  // Header filter operators text
  headerFilterOperatorContains: 'İçerir',
  headerFilterOperatorEquals: 'Eşittir',
  headerFilterOperatorStartsWith: 'İle başlar',
  headerFilterOperatorEndsWith: 'İle biter',
  headerFilterOperatorIs: 'Dir',
  headerFilterOperatorNot: 'Değil',
  headerFilterOperatorAfter: 'Sonrası',
  headerFilterOperatorOnOrAfter: 'Sonrası veya Eşit',
  headerFilterOperatorBefore: 'Öncesi',
  headerFilterOperatorOnOrBefore: 'Öncesi veya Eşit',
  headerFilterOperatorIsEmpty: 'Boş',
  headerFilterOperatorIsNotEmpty: 'Boş değil',
  headerFilterOperatorIsAnyOf: 'Herhangi biri',
  'headerFilterOperator=': 'Eşittir',
  'headerFilterOperator!=': 'Eşit değil',
  'headerFilterOperator>': 'Büyüktür',
  'headerFilterOperator>=': 'Büyük veya Eşit',
  'headerFilterOperator<': 'Küçüktür',
  'headerFilterOperator<=': 'Küçük veya Eşit',

  // Filter values text
  filterValueAny: 'herhangi biri',
  filterValueTrue: 'doğru',
  filterValueFalse: 'yanlış',

  // Column menu text
  columnMenuLabel: 'Menü',
  columnMenuShowColumns: 'Sütunları Göster',
  columnMenuManageColumns: 'Sütunları Yönet',
  columnMenuFilter: 'Filtre',
  columnMenuHideColumn: 'Sütunu Gizle',
  columnMenuUnsort: 'Sıralamayı Kaldır',
  columnMenuSortAsc: 'Artan Sırala',
  columnMenuSortDesc: 'Azalan Sırala',

  // Column header text
  columnHeaderFiltersTooltipActive: (count) =>
    (count !== 1 ? `${count} etkin filtre` : `${count} etkin filtre`),
  columnHeaderFiltersLabel: 'Filtreleri Göster',
  columnHeaderSortIconLabel: 'Sırala',

  // Rows selected footer text
  footerRowSelected: (count) =>
    (count !== 1
      ? `${count.toLocaleString()} satır seçildi`
      : `${count.toLocaleString()} satır seçildi`),

  // Total row amount footer text
  footerTotalRows: 'Toplam Satırlar:',

  // Total visible row amount footer text
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} / ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  checkboxSelectionHeaderName: 'Checkbox seçimi',
  checkboxSelectionSelectAllRows: 'Tüm satırları seç',
  checkboxSelectionUnselectAllRows: 'Tüm satırları seçme',
  checkboxSelectionSelectRow: 'Satırı seç',
  checkboxSelectionUnselectRow: 'Satırı seçme',

  // Boolean cell text
  booleanCellTrueLabel: 'evet',
  booleanCellFalseLabel: 'hayır',

  // Actions cell more text
  actionsCellMore: 'daha fazla',

  // Column pinning text
  pinToLeft: 'Sola sabitle',
  pinToRight: 'Sağa sabitle',
  unpin: 'Sabitlemeyi kaldır',

  // Tree Data
  treeDataGroupingHeaderName: 'Grup',
  treeDataExpand: 'çocukları gör',
  treeDataCollapse: 'çocukları gizle',

  // Grouping columns
  groupingColumnHeaderName: 'Grup',
  groupColumn: (name) => `${name} tarafından grupla`,
  unGroupColumn: (name) => `${name} tarafından gruplamayı durdur`,

  // Master/detail
  detailPanelToggle: 'Detay panelini aç/kapat',
  expandDetailPanel: 'Genişlet',
  collapseDetailPanel: 'Daralt',

  // Used core components translation keys
  MuiTablePagination: {},

  // Row reordering text
  rowReorderingHeaderName: 'Satır Yeniden Sıralama',

  // Aggregation
  aggregationMenuItemHeader: 'Toplam',
  aggregationFunctionLabelSum: 'toplam',
  aggregationFunctionLabelAvg: 'ortalama',
  aggregationFunctionLabelMin: 'minimum',
  aggregationFunctionLabelMax: 'maksimum',
  aggregationFunctionLabelSize: 'boyut',

};
