import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import { AppBar, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material';
import NewsEditor from '../../../news/NewsEditor';

function NewsDialog({ open, setOpen, onChange }) {
  const fullWidthDialog = open?.type === ('createNews') || ('editNews');

  function ModalDialog() {
    switch (String(open?.type)) {
      case 'editNews':
        return (
          <Dialog disableEnforceFocus fullWidth={fullWidthDialog} maxWidth={fullWidthDialog} open={!!open} onClose={() => setOpen(!open)}>
            <AppBar sx={{ position: 'relative', backgroundColor: 'primary' }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setOpen()}
                  aria-label="close"
                >
                  <EditIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  Haber veya Duyuru Güncelle
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent>
              <NewsEditor data={open?.data} tabName={1} onChange={(e) => setOpen(e)} />
            </DialogContent>
          </Dialog>
        );
      case 'createNews':
        return (
          <Dialog disableEnforceFocus fullWidth={fullWidthDialog} maxWidth={fullWidthDialog} open={!!open} onClose={() => setOpen(!open)}>
            <AppBar sx={{ position: 'relative', backgroundColor: 'primary' }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setOpen()}
                  aria-label="close"
                >
                  <AddIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  Haber veya Duyuru Ekle
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent>
              <NewsEditor data={open?.data} tabName={0} pageid={open.pageId} onChange={() => setOpen(false)} />
            </DialogContent>
          </Dialog>
        );
    }
  }

  return (
    <div>
      {open ?
        <ModalDialog />
        : null}
    </div>
  );
}

export default NewsDialog;
