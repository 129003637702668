import moment from 'moment';
import { Card, CardContent, CardMedia, Divider, Typography } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';
import { useEffect, useState, useContext, useLayoutEffect } from 'react';
import SectionSection from './section/section';
import SliderSection from './slider/slider';
import PageService from '../../../services/pageService';
import { DataContext } from '../../../contexts/DataContext';

const { Box } = require('@mui/system');

function PageDetail() {
  const { id } = useParams();
  const [pageInfo, setPageInfo] = useState();
  useEffect(() => {
    PageService.GetById(id).then((response) => {
      setPageInfo(response.data);
    });
  }, []);
  const { setSelectedIndex } = useContext(DataContext);
  useLayoutEffect(
    () => {
      setSelectedIndex(13);
    },
    [],
  );
  return (
    <Box>
      <Card elevation={10} sx={{ color: 'white', backgroundColor: 'primary.dark', marginBottom: '20px' }}>
        <CardMedia
          component="img"
          height="140"
          image="../../../logo.svg"
        />
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography sx={{ flex: 1 }} variant="h4" component="div">
              {pageInfo?.menuItemName}
            </Typography>
            <Typography sx={{ m: 1 }} textAlign="right" variant="caption">
              {moment(pageInfo?.lastUpdatedDate).format('DD MM YYYY HH:MM')}
            </Typography>
          </Box>
          <Typography gutterBottom variant="h6" component="div">
            {pageInfo?.subMenuItemName || pageInfo?.menuItemName}
          </Typography>
          <Typography variant="body2">
            {pageInfo?.subject}
          </Typography>

        </CardContent>
      </Card>
      <SectionSection id={id} />
      <Divider sx={{ mt: 2, mb: 2 }} />
      {/* <NewsSection id={id} />
      <Divider sx={{ mt: 2, mb: 2 }} /> */}
      <SliderSection params={id} />

    </Box>
  );
}
export default PageDetail;
