import { axiosCMSService } from './axios.config';

const endpoints = {
  create: 'slider/create',
  alllist: 'slider/alllist',
  getbyid: 'slider/getbyid',
  getapprovelist: 'slider/getapprovelist',
  update: 'slider/update',
  approveupdate: 'slider/approveupdate',
  delete: 'slider/delete',
  getbypageid: 'slider/getbypageid',
  updatestatus: 'slider/updatestatus',
};

const SliderService = {
  async Create(data) {
    return axiosCMSService.post(endpoints.create, data);
  },
  async AllList(filterApproved) {
    return axiosCMSService.get(`${endpoints.alllist}?filterApproved=${filterApproved}`);
  },
  async GetById(data) {
    return axiosCMSService.post(endpoints.getbyid, data);
  },
  async GetByPageId(data) {
    return axiosCMSService.get(`${endpoints.getbypageid}?id=${data}`);
  },
  async GetApproveList(data) {
    return axiosCMSService.get(endpoints.getapprovelist, data);
  },
  async Update(data) {
    return axiosCMSService.post(endpoints.update, data);
  },
  async ApproveUpdate(status, data) {
    return axiosCMSService.post(`${endpoints.approveupdate}?status=${status}`, data);
  },
  async UpdateStatus(data) {
    return axiosCMSService.post(endpoints.updatestatus, data);
  },
  async Delete(data) {
    return axiosCMSService.delete(`${endpoints.delete}?id=${data}`);
  },
};

export default SliderService;
