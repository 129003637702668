import { AppBar, Button, Dialog, DialogActions, DialogContent, FormControlLabel, IconButton, TextField, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SectionService from '../../../../services/sectionService';
import AntSwitch from '../../../../components/Switch';
import SectionItem from '../../../section/SectionItem';

const headerLabels = {
  itemedit: 'Alt İçerik Düzenle',
  additem: 'Alt İçerik Ekle',
  sectionedit: 'İçerik Düzenle',
  deleteitem: 'Alt İçeriği Sil',
  addsection: 'İçerik Ekle',
};
export default function EditDialog({ open, setOpen, onChange }) {
  const data = open?.data;
  const [sectionValue, setSectionValue] = useState({ name: open?.name || '', status: true });

  const Create = async () => {
    await SectionService.Create({ name: sectionValue?.name, pageId: open.pageId });
    onChange();
    setOpen();
  };

  useEffect(() => {
    setSectionValue({ name: open?.name || '', status: open?.status });
  }, [open]);

  function ModalSwitch() {
    {
      switch (String(open?.type)) {
        case 'additem':
          return (
            <DialogContent>
              <SectionItem open={open} onChange={onChange} tabName={0} />
            </DialogContent>
          );
        case 'deleteitem':
          return (
            <DialogContent>
              <SectionItem open={open} onChange={() => setOpen(false)} tabName={2} />
            </DialogContent>
          );
        case 'itemedit':
          return (
            <DialogContent>
              <SectionItem open={open} onChange={(e) => setOpen(e)} tabName={1} />
            </DialogContent>
          );
      }
    }
  }

  const fullWidthDialog = open?.type === ('additem' || 'itemedit');
  return (
    <div>
      {open ? (
        <Dialog disableEnforceFocus fullWidth={fullWidthDialog} maxWidth={fullWidthDialog} open={!!open} onClose={() => setOpen(!open)}>
          <AppBar sx={{ position: 'relative', backgroundColor: open.type.includes('delete') ? 'error.main' : 'primary' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setOpen()}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {headerLabels[open.type]}
              </Typography>
            </Toolbar>
          </AppBar>
          {open?.type === 'addsection' ? (
            <>
              <DialogContent>
                <TextField
                  autoFocus
                  value={sectionValue.name}
                  onChange={(e) => setSectionValue({ ...sectionValue, name: e.target.value })}
                  margin="dense"
                  id="name"
                  label="Bölüm Adı"
                  type="text"
                  fullWidth
                  variant="standard"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen()}>İptal</Button>
                <Button onClick={Create}>Ekle</Button>
              </DialogActions>
            </>
          )
            : (open?.type === 'sectionedit' ? (
              <>
                <DialogContent>
                  <TextField
                    autoFocus
                    value={sectionValue?.name}
                    onChange={(e) => setSectionValue((prev) => ({ ...prev, name: e.target.value }))}
                    margin="dense"
                    id="name"
                    label="Bölüm Adı"
                    type="text"
                    fullWidth
                    variant="standard"
                  />
                  <FormControlLabel label="Durum" control={<AntSwitch checked={sectionValue?.status} onChange={(e) => setSectionValue((prev) => ({ ...prev, status: e.target.checked }))} />} />
                </DialogContent>

                <DialogActions>
                  <Button onClick={() => setOpen()}>İptal</Button>
                  <Button onClick={() => SectionService.Update({ name: sectionValue?.name, id: open?.id, status: sectionValue.status }).then((response) => { setOpen(); onChange(); })}>Güncelle</Button>
                </DialogActions>
              </>
            )
              : <ModalSwitch />)}
        </Dialog>
      ) : null}
    </div>
  );
}
