import { Box, Button, Card, FormControlLabel, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { TreeView } from '@mui/lab';
import RemoveIcon from '@mui/icons-material/Remove';
import PageService from '../../../../services/pageService';
import SectionService from '../../../../services/sectionService';
import AntSwitch from '../../../../components/Switch';
import DeleteDialog from '../../../../components/DeleteDialog';
import { ApprovedStatus } from '../../../../constants/enum';
import StyledTreeItem from '../../../../components/CustomTreeItem';
import EditDialog from './editDialog';
import LinkedDialog from './linkedDialog';

function SectionSection(params) {
  const [sections, setSections] = useState();
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const [deleteEvents, setDeleteEvents] = useState({ open: false, itemId: 0 });

  const getSections = async (id) => {
    const result = await PageService.GetLinkedSections({ pageId: id, filterApproved: false, filterApprovedItem: false });
    setSections(result?.data);
  };

  const updateStatus = async (id, status) => {
    await SectionService.UpdateStatus({ sectionId: id, status });
    getSections(params.id);
  };
  const updateItemStatus = async (id, status) => {
    await SectionService.UpdateItemStatus({ id, status });
    getSections(params.id);
  };
  const unlinkSection = async (data) => {
    await PageService.UnlinkSection(data).then(() => {
      setDeleteEvents({ open: false, itemId: 0 });
    });

    getSections(params.id);
  };

  useEffect(() => {
    getSections(params.id);
  }, [editOpen]);

  return (
    <>
      <Card elevation={10} sx={{ backgroundColor: 'grey.50', padding: 3, borderRadius: '10px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography color="text.secondary" variant="h5">
            Bölümler
          </Typography>
          <Box>
            <Button
              variant="text"
              color="warning"
              onClick={() => setOpen({ pageId: params.id, pageSections: sections })}
              startIcon={<EditIcon style={{ fontSize: 12 }} />}
              style={{ textTransform: 'none' }}
              sx={{ mb: 0, mt: 0, marginRight: 2, fontSize: 12 }}
            >
              {' '}
              Bölüm Bağla
            </Button>
            <Button
              variant="text"
              color="secondary"
              startIcon={<AddIcon style={{ fontSize: 12 }} />}
              onClick={() => setEditOpen({ type: 'addsection', pageId: params.id })}
              style={{ textTransform: 'none' }}
              sx={{ mb: 0, mt: 0, marginRight: 2, fontSize: 12 }}
            >
              Bölüm Ekle
            </Button>
          </Box>
        </Box>
        <TreeView
          sx={{ overflowX: 'hidden' }}
          defaultCollapseIcon={<RemoveIcon sx={{ color: 'white', alignSelf: 'baseline', backgroundColor: 'primary.main', borderRadius: '3px' }} />}
          defaultExpandIcon={<AddIcon sx={{ color: 'white', alignSelf: 'baseline', backgroundColor: 'primary.main', borderRadius: '3px' }} />}
          defaultEndIcon={<div style={{ width: 24 }} />}
        >
          {sections?.map((x, i) => (
            <StyledTreeItem
              key={x.id}
              color="#1a73e8"
              bgColor="#e8f0fe"
              nodeId={x.id}
              labelText={x.name}
              sx={{ mt: 1 }}
              labelInfo={(
                <Box flexWrap="wrap" className="sectionInfo" sx={{ display: 'flex', flexWrap: 'nowrap', columnGap: 1, visibility: 'visible' }}>
                  {x.isApprove === ApprovedStatus.Rejected && (
                  <Typography color="error" variant="body2" sx={{ textAlign: 'center', mt: 1, mr: 3 }}>
                    Reddedilmiş !
                  </Typography>
                  )}
                  {x.isApprove === ApprovedStatus.Pending && (
                  <Typography color="orange" variant="body2" sx={{ textAlign: 'center', mt: 1, mr: 3 }}>
                    Onay Bekleniyor !
                  </Typography>
                  )}
                  {x.isApprove === ApprovedStatus.Confirm && (
                  <Typography color="green" variant="body2" sx={{ textAlign: 'center', mt: 1, mr: 3 }}>
                    Onaylanmış.
                  </Typography>
                  )}
                  <FormControlLabel
                    control={<AntSwitch checked={x.status} onChange={() => updateStatus(x.id, !x.status)} />}
                    slotProps={{ typography: { fontSize: 12 } }}
                    label="Durum"
                  />
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<AddIcon style={{ fontSize: 12 }} />}
                    onClick={() => setEditOpen({ type: 'additem', data: x.id })}
                    style={{ textTransform: 'none' }}
                    sx={{ mb: 0, mt: 0, marginRight: 2, fontSize: 12 }}
                  >
                    Alt İçerik Ekle
                  </Button>
                  <Button
                    variant="text"
                    color="secondary"
                    startIcon={<EditIcon style={{ fontSize: 12 }} />}
                    onClick={() => setEditOpen({ type: 'sectionedit', id: x.id, name: x.name, status: x.status })}
                    style={{ textTransform: 'none' }}
                    sx={{ mb: 0, mt: 0, marginRight: 2, fontSize: 12 }}
                  >
                    Düzenle
                  </Button>
                  <Button
                    variant="text"
                    color="error"
                    onClick={() => setDeleteEvents({ open: true, itemId: x.id })}
                    startIcon={<DeleteIcon style={{ fontSize: 12 }} />}
                    style={{ textTransform: 'none' }}
                    sx={{ mb: 0.5, mt: 0.5, mr: 2, fontSize: 12 }}
                  >
                    Bağlantıyı Sil
                  </Button>
                </Box>
)}
            >
              {x.sectionItems.map((y) => (
                <StyledTreeItem
                  color="#e3742f"
                  bgColor="#fcefe3"
                  key={y.createdDate}
                  nodeId={y.createdDate}
                  labelText={y.name}
                  labelInfo={(
                    <Box flexWrap="wrap" className="sectionInfo" sx={{ display: 'flex', visibility: 'visible', columnGap: 1 }}>
                      {y.isApprove === ApprovedStatus.Rejected && (
                      <Typography color="error" variant="body2" sx={{ textAlign: 'center', mt: 1, mr: 3 }}>
                        Reddedilmiş !
                      </Typography>
                      )}
                      {y.isApprove === ApprovedStatus.Pending && (
                      <Typography color="orange" variant="body2" sx={{ textAlign: 'center', mt: 1, mr: 3 }}>
                        Onay Bekleniyor !
                      </Typography>
                      )}
                      {y.isApprove === ApprovedStatus.Confirm && (
                      <Typography color="green" variant="body2" sx={{ textAlign: 'center', mt: 1, mr: 3 }}>
                        Onaylanmış.
                      </Typography>
                      )}
                      <FormControlLabel
                        control={<AntSwitch checked={y.status} onChange={() => updateItemStatus(y.id, !y.status)} />}
                        slotProps={{ typography: { fontSize: 12 } }}
                        label="Durum"
                      />
                      <Button
                        variant="text"
                        color="secondary"
                        startIcon={<EditIcon style={{ fontSize: 12 }} />}
                        onClick={() => setEditOpen({ type: 'itemedit', data: y })}
                        style={{ textTransform: 'none' }}
                        sx={{ mb: 0, mt: 0, marginRight: 2, fontSize: 12 }}
                      >
                        Düzenle
                      </Button>
                      <Button
                        variant="text"
                        color="error"
                        startIcon={<DeleteIcon style={{ fontSize: 12 }} />}
                        onClick={() => setEditOpen({ type: 'deleteitem', data: y })}
                        style={{ textTransform: 'none' }}
                        sx={{ mb: 0, mt: 0, marginRight: 2, fontSize: 12 }}
                      >
                        Alt İçeriği Sil
                      </Button>
                    </Box>
)}
                />

              ))}
            </StyledTreeItem>

          ))}
        </TreeView>
      </Card>
      <LinkedDialog open={open} setOpen={setOpen} onChange={() => getSections(params.id)} />
      <EditDialog open={editOpen} setOpen={setEditOpen} onChange={() => { setEditOpen(false); getSections(params.id); }} />
      <DeleteDialog
        open={deleteEvents.open}
        onClose={() => setDeleteEvents({ ...deleteEvents, open: false })}
        onConfirm={() => unlinkSection({ pageId: params.id, unlinkedItemId: deleteEvents.itemId })}
      />
    </>

  );
}
export default SectionSection;
