import { AppBar, Box, Dialog, FormControl, FormControlLabel, IconButton, MenuItem, Select, Tab, Tabs, TextField, Toolbar, Typography } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '../../components/Button';
import RoleService from '../../services/roleService';
import UserService from '../../services/userService';
import AntSwitch from '../../components/Switch';
import { NotifyContext } from '../../contexts/NotifyContext';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const UserKey = [
  { field: 'userId', headerName: 'Kullanıcı Id', width: 200 },
  { field: 'roleName',
    headerName: 'Rol',
    width: 250,
    renderCell: (params) => (
      params.value === 'New User' ? 'Yeni Kullanıcı' : params.value
    ) },
  { field: 'userName', headerName: 'Kullanıcı Adı', width: 300 },
  { field: 'status',
    headerName: 'Durum',
    width: 200,
    renderCell: (params) => (
      params.value === true ? <CheckIcon /> : <CloseIcon />
    ) },
  { field: 'createdDate',
    headerName: 'Oluşturulma Tarihi',
    width: 200,
    renderCell: (params) => {
      const splitValue = params.value.split('T');
      const split2 = splitValue[1].split(':');
      return (<Typography variant="body2">{`${splitValue[0]} ${split2[0]}:${split2[1]}`}</Typography>);
    } },
];

export default function UserRole() {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const { Notify } = useContext(NotifyContext);
  const [createRequest, setCreateRequest] = useState({ status: false, roleId: 5 });
  const GetUser = async () => {
    const result = await RoleService.List();
    setUsers(result?.data);
  };
  const GetRoles = async () => {
    const result = await RoleService.GetRoles();
    setRoles(result?.data);
  };
  const UpdateUser = async () => {
    const result = await RoleService.Update({ id: open.id, roleId: open.roleId, status: open.status });
    GetUser();
  };
  const CreateUser = async () => {
    if (!createRequest.firstName || !createRequest.email, !createRequest.lastName) {
      Notify('Tüm alanlar zorunludur!', 'error');
    } else {
      await UserService.Create(createRequest);
      GetUser();
      setCreateRequest({ status: false, roleId: 5 });
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    GetUser();
    GetRoles();
  }, []);
  return (
    <>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Kullanıcı Ekle" {...a11yProps(0)} />
          <Tab label="Kullanıcı Düzenle" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <CustomTabPanel value={value} index={0}>
        <Box component="form" noValidate sx={{ mt: 1, paddingRight: 7, paddingLeft: 7 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail Adresi"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(event) => setCreateRequest({ ...createRequest, email: event.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="İsim"
            onChange={(event) => setCreateRequest({ ...createRequest, firstName: event.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Soyisim"
            onChange={(event) => setCreateRequest({ ...createRequest, lastName: event.target.value })}
          />
          <Select
            sx={{ mt: 3 }}
            fullWidth
            defaultValue=""
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={createRequest?.roleId || ''}
            onChange={(event) => setCreateRequest({ ...createRequest, roleId: event.target.value })}
          >
            {roles && roles?.map((item) => (
              <MenuItem key={item.id} value={item.id}>{item.roleName}</MenuItem>
            ))}
          </Select>
          <FormControlLabel
            control={<AntSwitch checked={open.status} onChange={() => setCreateRequest({ ...createRequest, status: !createRequest.status })} />}
            label="Durum"
          />
          <Button
            variant="contained"
            fullWidth
            onClick={(e) => {
              e.preventDefault();
              CreateUser();
            }}
            sx={{ mt: 3, mb: 2 }}
          >
            Kullanıcı oluştur
          </Button>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Typography variant="h5" color="primary" sx={{ textAlign: 'center', mt: 5 }}>KULLANICILAR</Typography>
        <div style={{ height: 650, width: '100%', marginTop: 20 }}>
          <DataGrid
            onCellClick={(params) => setOpen(params.row)}
            rows={users}
            columns={UserKey}
            pageSize={10}
            pageSizeOptions={[10]}
            density="comfortable"
          />
        </div>
        <Dialog open={open} fullWidth onClose={() => setOpen(false)}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setOpen(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Kullanıcı Bilgilerini Güncelle
              </Typography>
              <Button autoFocus color="primary" onClick={UpdateUser}>
                Kaydet
              </Button>
            </Toolbar>
          </AppBar>

          <FormControl sx={{ p: 5 }}>
            <TextField id="outlined-basic" label="Kullanıcı Adı" variant="outlined" disabled defaultValue={open.userName} />
            <Select
              sx={{ mt: 3 }}
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={open.roleId || ''}
              onChange={(event) => setOpen({ ...open, roleId: event.target.value })}
            >
              {roles && roles?.map((item) => (
                <MenuItem key={item.id} value={item.id}>{item.roleName}</MenuItem>
              ))}
            </Select>
            <FormControlLabel
              control={<AntSwitch checked={open.status} onChange={() => setOpen({ ...open, status: !open.status })} />}
              label="Durum"
            />
          </FormControl>
        </Dialog>
      </CustomTabPanel>

    </>
  );
}
