import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PersonIcon from '@mui/icons-material/Person';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, Input, InputAdornment, InputLabel } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AuthContext } from '../contexts/AuthContext';
import UserService from '../services/userService';

export default function ProfileMenu({ user }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { logout } = React.useContext(AuthContext);
  const open = Boolean(anchorEl);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [request, setRequest] = React.useState({ currentPassword: '', newPassword: '' });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const ChangePassword = async () => {
    await UserService.ChangePassword(request).then((response) => {
      if (!response?.error) {
        setOpenDialog(false);
      }
    });
  };
  return (
    <div>
      <Button
        disableElevation
        style={{ fontSize: 12 }}
        variant="contained"
        startIcon={<PersonIcon />}
        onClick={handleClick}
      >
        {user?.fullName}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => setOpenDialog(true)}>Şifre Değiştir</MenuItem>
        <MenuItem onClick={logout}>Çıkış</MenuItem>

      </Menu>
      <Dialog open={openDialog} onClose={null}>
        <DialogTitle>Şifre Değiştir</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Mevcut şifrenizi ve yeni şifrenizi girmelisiniz. Şifrelerin eşleştiğinden emin olun.
          </DialogContentText>
          <FormControl fullWidth sx={{ mt: 1 }} variant="standard">
            <InputLabel htmlFor="outlined-adornment-password">Mevcut Şifreniz</InputLabel>
            <Input
              type={showPassword ? 'text' : 'password'}
              onChange={(event) => setRequest({ ...request, currentPassword: event.target.value })}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
                )}
              label="Password"
            />
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }} variant="standard">
            <InputLabel htmlFor="outlined-adornment-password">Yeni Şifreniz</InputLabel>
            <Input
              onChange={(event) => setRequest({ ...request, newPassword: event.target.value })}
              type={showPassword ? 'text' : 'password'}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
                )}
              label="Password"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={ChangePassword}>Değiştir</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
