import { TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '../../components/Button';
import { NotifyContext } from '../../contexts/NotifyContext';
import LinkShortenerService from '../../services/linkShortenerService';

const useStyles = makeStyles({
  root: {
    padding: 0,
    borderRadius: 10,
    marginBottom: 10,
    '& .icon': {
      visibility: 'hidden',
    },
    '&:hover .icon': {
      visibility: 'visible',
    },
  },
});

function LinkShortener() {
  const [urlList, setUrlList] = useState();
  const [url, setUrl] = useState();
  const { Notify } = useContext(NotifyContext);
  const classes = useStyles();

  const getUrlList = async () => {
    const result = await LinkShortenerService.GetAllUrls();
    setUrlList(result?.data);
    return result;
  };

  const onCreateShortUrl = async () => {
    const result = await LinkShortenerService.Create(url);
    Notify(result);
    getUrlList();
    return result;
  };

  const deleteUrl = async (id) => {
    const result = await LinkShortenerService.Delete(id);
    Notify(result);
    getUrlList();
    return result;
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      field: 'shortUrl',
      headerName: 'Kısa URL',
      flex: 1,
    },
    {
      field: 'url',
      headerName: 'Uzun URL',
      flex: 1,
    },
    {

      field: 'comment',
      headerName: 'Açıklama',
      flex: 1,
    },
    {
      headerName: 'İşlem',
      flex: 0.5,
      align: 'center',
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const { api } = params;
          const thisRow = {};
          api
            .getAllColumns()
            .filter((c) => c.field !== '__check__' && !!c)
            .forEach(
              (c) => { thisRow[c.field] = params.getValue(params.id, c.field); },
            );
          deleteUrl(thisRow.id);
        };

        return <Button color="error" variant="text" onClick={onClick}>SİL</Button>;
      },
    },
  ];

  // useEffect(() => {
  //   getUrlList()
  // }, []);

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField onChange={(e) => setUrl((x) => ({ ...x, url: e.target.value }))} sx={{ display: 'flex', flex: 0.7, mr: 6 }} id="standard-basic" label="Kısaltılacak URL" variant="standard" />
        <TextField onChange={(e) => setUrl((x) => ({ ...x, comment: e.target.value }))} multiline sx={{ display: 'flex', flex: 0.2 }} id="standard-basic" label="Not" variant="standard" />
        <Button onClick={onCreateShortUrl} sx={{ display: 'flex', flex: 0.1, ml: 6 }} variant="contained">Url Kısalt</Button>
      </Box>

      <Box sx={{ height: 400, width: '100%' }}>
        <Typography textAlign="center" mt={4} mb={2} variant="h6"> URL Listesi</Typography>
        <DataGrid
          rows={urlList || []}
          columns={columns}
          pageSize={5}
          disableColumnSelector
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
    </Box>
  );
}
export default LinkShortener;
