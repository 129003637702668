import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Drawer } from '@mui/material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useLocation } from 'react-router-dom';
import { DrawerList } from './ListItems';
import ProfileMenu from './ProfileMenu';
import Loader from './Loader';

const drawerWidth = 240;
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        TRA Tech
      </Link>
      {' '}
      {new Date().getFullYear()}
      .
    </Typography>
  );
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const mdTheme = createTheme({
  typography: {
    fontFamily: 'Arial, sans-serif',
    h1: {
      fontSize: '6rem',
      '@media (max-width:600px)': {
        fontSize: '5rem',
      },
    },
    h2: {
      fontSize: '3.75rem',
      '@media (max-width:600px)': {
        fontSize: '3rem',
      },
    },
    h3: {
      fontSize: '3rem',
      '@media (max-width:600px)': {
        fontSize: '2.5rem',
      },
    },
    h4: {
      fontSize: '2.125rem',
      '@media (max-width:600px)': {
        fontSize: '1.875rem',
      },
    },
    h5: {
      fontSize: '1.5rem',
      '@media (max-width:600px)': {
        fontSize: '1.25rem',
      },
    },
    h6: {
      fontSize: '1.25rem',
      '@media (max-width:600px)': {
        fontSize: '1rem',
      },
    },
    subtitle1: {
      fontSize: '1rem',
      '@media (max-width:600px)': {
        fontSize: '0.9375rem',
      },
    },
    subtitle2: {
      fontSize: '0.875rem',
      '@media (max-width:600px)': {
        fontSize: '0.8125rem',
      },
    },
    body1: {
      fontSize: '1rem',
      '@media (max-width:600px)': {
        fontSize: '0.9375rem',
      },
    },
    body2: {
      fontSize: '0.875rem',
      '@media (max-width:600px)': {
        fontSize: '0.8125rem',
      },
    },
    button: {
      fontSize: '0.875rem',
      '@media (max-width:600px)': {
        fontSize: '0.8125rem',
      },
    },
    caption: {
      fontSize: '0.75rem',
      '@media (max-width:600px)': {
        fontSize: '0.6875rem',
      },
    },
    overline: {
      fontSize: '0.625rem',
      '@media (max-width:600px)': {
        fontSize: '0.5625rem',
      },
    },
  },
});

function Layout({ children, user }) {
  const [open, setOpen] = React.useState(true);
  const location = useLocation();
  const toggleDrawer = () => {
    setOpen(!open);
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <Loader />
        <CssBaseline />
        {/* Navbar Bölümü */}
        <AppBar position="absolute" open={open}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {location?.state?.title}
            </Typography>
            <ProfileMenu user={user} />
          </Toolbar>
        </AppBar>
        {/* Drawer Bölümü */}
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <Toolbar
            sx={{
              display: 'flex',
              marginTop: 2,
              marginBottom: 2,
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            {' '}
            <img
              src="/logo.svg"
              height={80}
              style={{ marginTop: 10, marginBottom: 10 }}
              width={240}
              alt="TRA"
            />
            <Button sx={{ position: 'absolute', top: 0 }} onClick={toggleDrawer}>
              <KeyboardDoubleArrowLeftIcon />
            </Button>
          </Toolbar>
          <Divider />
          <DrawerList />
        </Drawer>
        {/* Sayfa İçeriği */}
        <Main open={open}>
          <DrawerHeader />
          <Container fixed maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {children}
          </Container>

        </Main>
      </Box>
    </ThemeProvider>
  );
}

export default Layout;
